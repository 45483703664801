@use 'colors';

.app {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        background-color: colors.$color_true_blue;
        color: colors.$color_white;

        width: 100%;
        text-align: center;
        margin: 0;
        margin-bottom: 10px;
        padding: 10px;
    }
}