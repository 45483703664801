@use '../../../scss/colors';

$color_past_fg: colors.$color_true_blue;
$color_today_fg: colors.$color_orange_web;
$color_disabled_fg: colors.$color_platinum;

@mixin set-fg-bg-colors($fg, $bg, $border) {
    border: 1px solid $border;
    color: $fg;
    background-color: $bg;
}

@mixin set-colors($fg, $bg: colors.$color_white) {
    &:not(.selected) {
        @include set-fg-bg-colors($fg, $bg, $fg);
    }

    &.selected {
        @include set-fg-bg-colors($bg, $fg, $fg);
    }

    &:not(:disabled) {
        &:not(.selected) {
            &:hover {
                @include set-fg-bg-colors($bg, $fg, $fg);
            }
        }

        &.selected {
            &:hover {
                @include set-fg-bg-colors($bg, lighten($fg, 30%), lighten($fg, 30%));
            }
        }
    }
}

.toggle-button {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 4px;
    cursor: pointer;
  
    @include set-colors($color_past_fg);
  
    &:disabled {
      @include set-colors($color_disabled_fg);
      cursor: unset;
    }
    
    &.highlighted {
        @include set-colors($color_today_fg);
    }
}