$cell-size: minmax(1fr, 30px);

.yv-container
{
  display: grid;

  max-width: 100vw;
  max-height: 100vh;

  grid-template-rows: [first] repeat(32, $cell-size);
  grid-template-columns: repeat(12, $cell-size);
  
  justify-content: center;
  gap: 0.25em;
  
  .yv-label {
    grid-row: 0 / span 1;
    justify-self: center;
    align-self: center;
    font-weight: bold;
  }
  
  @for $i from 0 through 12 {
    .yv-month-#{$i} {
      grid-column: #{$i + 1} / span 1;
    }
  }
  
  @for $i from 0 through 31 {
    .yv-day-#{$i} {
      grid-row: #{$i + 1} / span 1;
      button {
        aspect-ratio: 1;
      }
    }
  }
}
